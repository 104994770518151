// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-evie-js": () => import("./../../../src/pages/evie.js" /* webpackChunkName: "component---src-pages-evie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photography-js": () => import("./../../../src/pages/photography.js" /* webpackChunkName: "component---src-pages-photography-js" */),
  "component---src-pages-spacejam-js": () => import("./../../../src/pages/spacejam.js" /* webpackChunkName: "component---src-pages-spacejam-js" */),
  "component---src-pages-uoft-acc-js": () => import("./../../../src/pages/uoft_acc.js" /* webpackChunkName: "component---src-pages-uoft-acc-js" */)
}

